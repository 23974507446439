import { environment } from './../../environments/environment.local';
import { Injectable } from '@angular/core';

import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(
    protected updates: SwUpdate,
    protected toastController: ToastController
  ) {
    this.registerUpdate();
  }
  checkUpdates(){
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate().then(() => console.log('checking new Version'));
    }
  }
  registerUpdate(){
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe( (evt:VersionEvent) => {
        console.log('check updates');
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            this.infoText('Neue Version der App wird heruntergeladen.');
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            this.readToReload();

            break;
          case 'VERSION_INSTALLATION_FAILED':
            this.infoText('Es ist ein Fehler aufgetreten.');
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
          default:
            console.log('other');
        }
      });
    }
  }

  async infoText(text:string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 5000
    });
    toast.present();
  }
  async readToReload(){
    const toast = await this.toastController.create({
      message: 'Neue Version der App kann verwendet werden.',
        position: 'top',
        buttons: [
          {
            icon: 'refresh',
            text: 'neu laden',
            handler: () => {
              window.location.reload();
            }
          }
        ]
      });
      await toast.present();
  }

}