import { BaseNumberedPanelComponent } from './../base-numbered-panel/base-numbered-panel.component';
import { Component } from '@angular/core';

@Component({
  selector: 'numbered-panel-outside',
  templateUrl: './numbered-panel-outside.component.html',
  styleUrls: ['./numbered-panel-outside.component.scss'],
})
export class NumberedPanelOutsideComponent extends BaseNumberedPanelComponent {

}

