import { SwiperOptions } from 'swiper/types';
import { SwiperConfigurationService } from './../../services/swiper-configuration.service';
import { ModalController } from '@ionic/angular';
import { BaseCityModalComponent } from 'src/app/modals/base-city-modal/base-city-modal.component';
import { Component, ViewChild} from '@angular/core';
import { SwiperComponent } from 'swiper/angular';

type ThemaTypes = 'thema1' | 'thema2' | 'thema3' | 'thema4' ;
@Component({
  selector: 'app-informationen',
  templateUrl: './informationen.component.html',
  styleUrls: ['./informationen.component.scss'],
})
export class InformationenComponent extends BaseCityModalComponent{
  
  swiperConf: SwiperOptions;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(modalController:ModalController, swiperConfiService: SwiperConfigurationService){
    super(modalController);
    this.swiperConf = swiperConfiService?.getCardConfiguration();    
    this.swiperConf.spaceBetween = 15;
    this.swiperConf.navigation = false;
    this.swiperConf.loop = true; 

  }
  jumpTo(index: number){
    this.swiper.swiperRef.slideTo(index);

  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }
  
}
