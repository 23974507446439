import { FormulareDefinitionStructure, QuestionDefinitionStructure, QuestionGroupDefinitionStructure } from './../models/form-definitions';
import { QuestionStatus, StatusGroupArea, NewFormType, ContactDataInfo, SubmitDataInfo } from './../models/forms';
import { share, filter, flatMap, debounceTime, concatMap, catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DatabaseService } from 'src/app/db/queries';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, defer, forkJoin, from, merge, Observable, of, Subject, throwError } from 'rxjs';
import { FormInfo, FormStatus, MyForm } from 'src/app/models/forms';
import { City } from 'src/app/models/city';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from '../models/main-types';
import { TeamEntity } from '../models/team';

@Injectable({
  providedIn: 'root',
})
export class FormularService {
  base_url = environment.url;
  _get_url = this.base_url + 'public-api/form/';
  _new_form_url = this.base_url + 'public-api/new-form/';
  _form_type_url = this.base_url + 'public-api/form-type/';
  _save_url = this.base_url + 'public-api/form/';
  _pdf_url = this.base_url + 'public-api/pdf/';
  _submit_url = this.base_url + 'public-api/submit/';
  _contact_form_url = this.base_url + 'public-api/contact/';
  myforms$?: Observable<MyForm[]>;

  delayedSavedForm$: Subject<MyForm> = new Subject<MyForm>();
  formSaved$ = new Subject<MyForm>();

  constructor(private http: HttpClient, private db: DatabaseService) {
    const requestSave$ = this.delayedSavedForm$.pipe(debounceTime(5000));

    requestSave$.subscribe((myForm) => {
      this.doSendForm(myForm);
    });
  }

  getFormularDefinition(city: City, entity?: TeamEntity): FormulareDefinitionStructure {
    const useCustomText = !!entity;
    const baseDef: FormulareDefinitionStructure = {
      allgemeines: [
        {
          number: 1,
          type: 'generalquestion',
          title: useCustomText ? 'Wie viele Menschen leben hier?' : `Wie viele Menschen leben in ${city?.name}?`,
          explizitMarked: false,
          fields: [{ field: 'population_total', type: 'number' }],
        },
        {
          number: 2,
          type: 'multiquestion',
          title: useCustomText ? 'Wie hat sich die Einwohner*innenzahl verändert?' : `Wie hat sich die Einwohner*innenzahl in ${city?.name} verändert?`,
          explizitMarked: false,
          subtitle: 'Absolute Zahl pro Jahrzehnt',
          groups: [
            {
              label: '2010er Jahre',
              fields: ['v2_births_2010', 'v2_outflux_2010', 'v2_influx_2010'],
            },
            {
              label: '2000er Jahre',
              fields: ['v2_births_2000', 'v2_outflux_2000', 'v2_influx_2000'],
            },
            {
              label: '1990er Jahre',
              fields: ['v2_births_1990', 'v2_outflux_1990', 'v2_influx_1990'],
            },
            {
              label: '1980er Jahre',
              fields: ['v2_births_1980', 'v2_outflux_1980', 'v2_influx_1980'],
            },
          ],
          fields: [
            { field: 'v2_births_1980', type: 'number' },
            { field: 'v2_births_1990', type: 'number' },
            { field: 'v2_births_2000', type: 'number' },
            { field: 'v2_births_2010', type: 'number' },
            { field: 'v2_influx_1980', type: 'number' },
            { field: 'v2_influx_1990', type: 'number' },
            { field: 'v2_influx_2000', type: 'number' },
            { field: 'v2_influx_2010', type: 'number' },
            { field: 'v2_outflux_1980', type: 'number' },
            { field: 'v2_outflux_1990', type: 'number' },
            { field: 'v2_outflux_2000', type: 'number' },
            { field: 'v2_outflux_2010', type: 'number' },
          ],
        },
        {
          number: 3,
          type: 'multiquestion',
          title: `Wie oft hält der Bus in ${city.name}?`,
          explizitMarked: false,
          fields: [
            { field: 'bus_connection_school', type: 'number' },
            { field: 'bus_connection_others', type: 'number' },
          ],
        },
        {
          number: 4,
          type: 'generalquestion',
          title: 'Wie weit ist es zur Autobahn?',
          explizitMarked: false,
          fields: [{ field: 'distance_highway', type: 'number', title: '' }],
        },
        {
          number: 5,
          type: 'generalquestion',
          title: 'Wo ist der nächste Bahnhof?',
          explizitMarked: false,
          fields: [{ field: 'distance_train', type: 'number', title: '' }],
        },
        {
          number: 6,
          type: 'rangequestion',
          title: 'Wie schnell ist das Internet?',
          explizitMarked: false,
          fields: [{ field: 'internet', type: 'number' }],
        },
        {
          number: 7,
          type: 'rangequestion',
          title: 'Wie gut ist der Handyempfang?',
          explizitMarked: false,
          fields: [{ field: 'connectivity', type: 'number' }],
        },
        {
          number: 8,
          type: 'group',
          title: `Wer engagiert sich in ${city.name}?`,
          explizitMarked: false,
          fields: [{ type: 'groups' }],
        },
        {
          number: 9,
          type: 'generalquestion',
          title: 'Wie viele Menschen engagieren sich?',
          explizitMarked: false,
          fields: [{ field: 'sum_group_members', type: 'number' }],
        },
        {
          number: 10,
          type: 'events',
          title: `Was wird in ${city.name} gefeiert?`,
          explizitMarked: false,
          fields: [{ field: 'events', type: 'array' }],
        },
      ],
      unterwegs: [
        {
          number: 1,
          type: 'multiquestion',
          title: useCustomText ? 'Wie alt sind die Bewohner*innen?' : `Wie alt ist ${city.name}?`,
          explizitMarked: true,
          fields: [
            { field: 'population_17', type: 'number' },
            { field: 'population_29', type: 'number' },
            { field: 'population_30', type: 'number' },
            { field: 'population_65', type: 'number' },
          ],
          comparator: {
            positive: ['population_total'],
            negative: ['population_17', 'population_20', 'population_30', 'population_65'],
            label: 'Personen sind noch keiner Altersgruppe zugeordnet.',
          },
        },
        {
          number: 2,
          type: 'multiquestion',
          title: useCustomText ? 'Wann sind die Bewohner*innen da?' : `Wann sind die Bewohner*innen in ${city.name}?`,
          explizitMarked: true,
          fields: [
            { field: 'population_always', type: 'number' },
            { field: 'population_afternoon_weekend', type: 'number' },
            { field: 'population_weekend', type: 'number' },
            { field: 'population_sporadic', type: 'number' },
          ],
        },
        {
          number: 3,
          type: 'swiper-card',
          panelType: 'outside',
          title: useCustomText ? 'In welchen Gebäuden wird gelebt?' : `In welchen Gebäuden wird gelebt in ${city.name}?`,
          explizitMarked: true,
          fields: [
            { field: 'houses_type_farm', type: 'number' },
            { field: 'houses_type_manor', type: 'number' },
            { field: 'houses_type_before_1945', type: 'number' },
            { field: 'houses_type_until_1990', type: 'number' },
            { field: 'houses_type_after_1990', type: 'number' },
            { field: 'houses_type_building_block', type: 'number' },
            { field: 'houses_type_bungalow', type: 'number' },
          ],
        },
        {
          number: 4,
          type: 'generalquestion',
          title: 'Gibt es Leerstand?',
          explizitMarked: true,
          fields: [{ field: 'houses_empty_absolute', type: 'number' }],
        },
        {
          number: 5,
          type: 'swiper-card',
          panelType: 'outside',
          title: useCustomText ? 'Welche Gartentypen gibt es?' : `Welche Gartentypen gibt es in ${city.name}?`,
          explizitMarked: true,
          fields: [
            { field: 'garden_type1', type: 'number' },
            { field: 'garden_type2', type: 'number' },
            { field: 'garden_type3', type: 'number' },
            { field: 'garden_type4', type: 'number' },
          ],
        },
        {
          number: 6,
          type: 'swiper-card',
          panelType: 'outside',
          title: useCustomText ? 'Wie groß sind die Grundstücke?' : `Wie groß sind die Grundstücke in ${city.name}?`,
          explizitMarked: true,
          fields: [
            { field: 'garden_area_300', type: 'number' },
            { field: 'garden_area_1000', type: 'number' },
            { field: 'garden_area_2000', type: 'number' },
            { field: 'garden_area_greater', type: 'number' },
          ],
        },
        {
          number: 7,
          type: 'swiper-card',
          title: useCustomText ? 'Was findet man auf den Grundstücken?' : `Was findet man auf den Grundstücken in ${city.name}?`,
          explizitMarked: true,
          fields: [
            { field: 'farming_type9', type: 'number' },
            { field: 'farming_type6', type: 'number' },
            { field: 'farming_type7', type: 'number' },
            { field: 'farming_type8', type: 'number' },
            { field: 'farming_type1', type: 'number' },
            { field: 'farming_type3', type: 'number' },
            { field: 'farming_type2', type: 'number' },
            { field: 'farming_type5', type: 'number' },
            { field: 'farming_type4', type: 'number' },
          ],
        },
      ],
      map: [
        {
          number: 1,
          type: 'poi',
          title: 'Wie ist  ausgestattet?',
          explizitMarked: false,
          fields: [{ field: 'facilities', type: 'maps' }],
        },
        {
          number: 2,
          type: 'poi',
          title: useCustomText ? 'Welche Gewerbe gibt es hier?' : 'Welche Gewerbe gibt es?',
          explizitMarked: false,
          fields: [{ field: 'business', type: 'maps' }],
        },
        {
          number: 3,
          type: 'poi',
          title: 'Welche Orte der Gemeinschaft gibt es?',
          explizitMarked: false,
          fields: [{ field: 'community', type: 'maps' }],
        },
      ],
    };
    if (!entity) {
      return baseDef;
    } else {
      const custom: FormulareDefinitionStructure = {
        allgemeines: [],
        unterwegs: [],
        map: [],
      };
      let page = 1;
      baseDef.map.forEach((ms) => {
        if (this.containsAnyField(ms, entity)) {
          custom.map.push({ ...ms, number: page++ });
        }
      });
      page = 1;
      baseDef.allgemeines.forEach((ms) => {
        if (this.containsAnyField(ms, entity)) {
          custom.allgemeines.push({ ...ms, number: page++ });
        }
      });
      if (!custom.allgemeines.some((ms) => ms.fields.some((f) => f.type === 'groups'))) {
        custom.unterwegs = custom.allgemeines;
        custom.allgemeines = [];
      } else {
        page = 1;
      }
      baseDef.unterwegs.forEach((ms) => {
        if (this.containsAnyField(ms, entity)) {
          custom.unterwegs.push({ ...ms, number: page++ });
        }
      });
      return custom;
    }
  }

  containsAnyField(question: QuestionGroupDefinitionStructure, def: { fields: string[]; maps: string[]; groups: boolean }) {
    return question.fields.some((q) => {
      return (q.field && def.fields.includes(q.field)) || (q.type === 'groups' && def.groups) || (q.field && q.type === 'maps' && def.maps.includes(q.field));
    });
  }

  getFormStatusDefault(): FormStatus {
    return {
      allgemeines: {
        questions: new Map<number, QuestionStatus>(),
        status: '',
        answered: 0,
      },
      unterwegs: {
        questions: new Map<number, QuestionStatus>(),
        status: '',
        answered: 0,
      },
      map: {
        questions: new Map<number, QuestionStatus>(),
        status: '',
        answered: 0,
      },
    };
  }

  resumeStatusGroupArea(group: StatusGroupArea, def: QuestionGroupDefinitionStructure[]) {
    group.answered = 0;
    const groupStatus = Array.from(group.questions.values());
    group.status = '';
    groupStatus.forEach((status: QuestionStatus) => {
      if (['completed', 'dontknow'].includes(status)) {
        group.answered++;
      }
    }, undefined);
    if (group.answered > 0) {
      if (group.answered !== def.length) {
        group.status = 'incomplete';
      } else {
        group.status = 'completed';
      }
    }
  }

  calculateQuestionAreaStatus(form: FormInfo, questionDefs: QuestionDefinitionStructure[], explizitComplete: boolean): QuestionStatus {
    return questionDefs.reduce((status: QuestionStatus | undefined, qd) => {
      if (status === undefined) {
        const qStatus = this.calculateQuestionStatus(form, qd);
        return qStatus;
      } else {
        if (status === 'incomplete') {
          return 'incomplete';
        }
        const qStatus = this.calculateQuestionStatus(form, qd);
        if (
          (qStatus === '' && ['completed', 'dontknow'].includes(status)) ||
          (status === '' && ['completed', 'dontknow'].includes(qStatus)) ||
          (explizitComplete && ['completed', 'dontknow'].includes(status))
        ) {
          return 'incomplete';
        }
        if (qStatus === status) {
          return qStatus;
        }
      }
    }, undefined);
  }
  calculateQuestionStatus(form: FormInfo, questionDef: QuestionDefinitionStructure): QuestionStatus {
    switch (questionDef.type) {
      case 'number':
        const value = form.fields[questionDef.field];
        if (!value) return '';
        return value < 0 ? 'dontknow' : 'completed';
      case 'groups':
        return form.groups?.length > 0 ? 'completed' : '';
      case 'array':
        return form.fields[questionDef.field]?.length > 0 ? 'completed' : '';
      case 'maps':
        return form.maps.some((poi) => poi.datas.some((d) => d.fieldId === questionDef.field)) ? 'completed' : '';
      default:
        return '';
    }
  }

  async createForm(city: City, startForm: NewFormType): Promise<MyForm> {
    const newForm$ = new Promise<MyForm>((resolve, reject) => {
      this.getNewForm(city, startForm).subscribe(
        async (form) => {
          let myForm: MyForm = {
            id: form.id,
            status: 'new',
            city_id: city.id,
            created_at: new Date(),
            formStatus: this.getFormStatusDefault(),
            form,
          };
          await this.db
            .updateForm(myForm)
            .then(() => resolve(myForm))
            .catch((e) => reject(e));
        },
        () => {
          reject();
        }
      );
    });

    return newForm$;
  }

  getFormById(city: City, id: UUID): Promise<MyForm> {
    const newForm$ = new Promise<MyForm>(async (resolve, reject) => {
      try {
        const mf: MyForm = await this.db.getFormById(id);
        if (mf) {
          resolve(mf);
        }
      } catch (err) {
        console.log(err);
      }
      this.getExistingFormById(city, id).subscribe(
        async (form) => {
          const mf: MyForm = await this.db.addForm(form, city, this.getFormStatusDefault());
          resolve(mf);
        },
        (e) => reject(e)
      );
    });
    return newForm$;
  }

  getNewForm(location: City, startForm: NewFormType): Observable<FormInfo | null> {
    return this.http.post<FormInfo | null>(this._new_form_url + location.id, startForm);
  }
  getExistingFormById(location: City, id: UUID): Observable<FormInfo | null> {
    return this.http.get<FormInfo | null>(this._get_url + location.id, {
      params: { id },
    });
  }
  saveFormType(form_id: String, formType: NewFormType): Observable<FormInfo> {
    return this.http.post<FormInfo>(this._form_type_url + form_id, formType);
  }
  getMyForms(): Observable<MyForm[]> {
    const liveForms = new Subject<MyForm[]>();
    this.db.findMyForms().then((forms) => {
      liveForms.next(forms);
    });
    const sub$ = this.db
      .getTableChanges('myForms')
      .pipe(share())
      .subscribe((e) => {
        this.db.findMyForms().then((forms) => {
          liveForms.next(forms);
        });
      });
    return liveForms.asObservable();
  }

  getFormInfoByCityId(id: UUID, city: City): Observable<MyForm> {
    const $firstForm = this.db.getFormById(id).then(async (form) => {
      if (form) {
        if (!form.formStatus) {
          form.formStatus = this.getFormStatusDefault();
        }
        return form;
      } else {
        return await this.getFormById(city, id);
      }
    });
    const updated$ = this.db.getTableChanges('myForms').pipe(
      share(),
      filter((e) => e.key === id),
      flatMap((change) => {
        return defer(() => from(this.db.getFormById(id)));
      })
    );
    return merge(
      defer(() => from($firstForm)),
      updated$
    );
  }
  saveForm(myForm: MyForm, cityId: number): Promise<any> {
    return this.db.updateForm(myForm).then(() => console.log('saved'));
  }
  sendForm(myForm: MyForm): Observable<MyForm> {
    this.delayedSavedForm$.next(myForm);
    return this.formSaved$.asObservable();
  }

  doSendForm(myForm: MyForm): Observable<MyForm> {
    const obs$ = this.http.post<FormInfo>(this._save_url + myForm.city_id, myForm.form).pipe(
      share(),
      map((form_info_new_status: FormInfo) => {
        myForm.form = form_info_new_status;
        if (myForm.status !== 'error') {
          myForm.status = 'commited';
        }
        return myForm;
      })
    );

    obs$.subscribe(() => this.formSaved$.next(myForm));
    return obs$;
  }

  sendContactForm(data: ContactDataInfo, mf: MyForm, location: City): Observable<FormInfo> {
    const contact$ = this.http.post<FormInfo>(this._contact_form_url + location.id, data).pipe(share());
    contact$.subscribe(
      (fi) => {
        mf.form = fi;
        mf.details.agree_terms = data.agree_terms;
        mf.details.name = data.name;
        mf.details.email = data.email;
        mf.details.institution = data.institution;
        mf.details.receiveNews = data.receiveNews;
        mf.details.receiveNewsletter = data.receiveNewsletter;
        this.saveForm(mf, location.id);
      },
      () => {
        mf.status = 'error';
        mf.errorType = 'contact';
        this.saveForm(mf, location.id);
      }
    );

    return contact$;
  }

  getFormPDFResourceLocation(uuid: string, location: City): string {
    return `${this._pdf_url}${location.id}?id=${uuid}`;
  }

  delete(form: MyForm) {
    if (form.id) {
      this.db.deleteForm(form.id);
    }
  }

  async submitForm(data: SubmitDataInfo, mf: MyForm, location: City): Promise<FormInfo> {
    try {
      mf = await this.doSendForm(mf).toPromise();
      const fi = await this.http.post<FormInfo>(this._submit_url + location.id, data).toPromise();
      mf.form = fi;
      mf.details.probability = data.probability;
      mf.status = 'submitted';
      mf.errorType = undefined;
    } catch (error) {
      mf.status = 'error';
      mf.errorType = 'submit';
      this.saveForm(mf, location.id);
      throw error;
    }
    this.saveForm(mf, location.id);
    return mf.form;
  }

  syncMyForms() {
    this.db.findMyForms().then((myforms) => {
      myforms
        .filter((mf) => mf.status === 'error')
        .forEach((mf) => {
          this.db.getCitiesById(mf.city_id).then((city) => {
            if (mf.errorType === 'submit') {
              this.submitForm(
                {
                  id: mf.form.id,
                  probability: mf.details.probability,
                },
                mf,
                city
              ).then(() => {
                console.log(`submitted => ${mf.city_id}`);
              });
            }
            if (mf.errorType === 'contact') {
              this.sendContactForm(
                {
                  id: mf.form.id,
                  agree_terms: mf.details.agree_terms,
                  name: mf.details.name,
                  email: mf.details.email,
                  institution: mf.details.institution,
                  receiveNews: mf.details.receiveNews,
                  receiveNewsletter: mf.details.receiveNewsletter,
                },
                mf,
                city
              ).subscribe(() => {
                mf.status = 'submitted';
                mf.errorType = undefined;
                this.saveForm(mf, city.id);
              });
            }
          });
        });
    });
  }
}
