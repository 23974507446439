import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef } from '@angular/core';
import { BaseQuestionComponent } from '../base-question/base-question.component';

export const GENERAL_QUESTION_INPUT_FIELD: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => GeneralquestionComponent),
  multi: true
};

@Component({
  selector: 'generalquestion',
  templateUrl: './generalquestion.component.html',
  styleUrls: ['./generalquestion.component.scss'],
  providers: [GENERAL_QUESTION_INPUT_FIELD]
})
export class GeneralquestionComponent extends BaseQuestionComponent  {
}
