import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import {
  PickerController,
  PickerOptions,
  PickerColumnOption,
} from "@ionic/angular";

@Directive()
export class BaseQuestionComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() label: string = "";
  @Output() valueChange: EventEmitter<number | null> = new EventEmitter<
    number | null
  >();
  @Input() min: number = 0;
  @Input() max: number = 10000;
  @Input() picture?: string | string[];
  @Input() selected_picture?: string | string[];
  @Input() dontKnow: number = -1;
  @Input() unity?: string;
  @Input() defaultValue?: number | null;
  @Input() help?: string;
  @Input() useWheel: boolean = false;
  @Input() hideButtons: boolean = false;

  picture_location?: string;
  indexed_picture?: number;
  timeoutHandler: any;
  _current_value: number | null = null;
  counter: number | null = null;
  constructor(private picker: PickerController) {}
  ngAfterViewInit(): void {
    this.pictureChange(false);
  }

  pictureChange(nextpicture: boolean) {
    let pics = this.picture;
    if (this._current_value > 0 && this.selected_picture) {
      pics = this.selected_picture;
    }
    if (pics instanceof Array) {
      let currendIndex = this.indexed_picture ?? 0;
      if (nextpicture) {
        currendIndex++;
      }
      if (!pics[currendIndex]) {
        currendIndex = 0;
      }
      this.indexed_picture = currendIndex;
      this.picture_location = pics[currendIndex];
    } else {
      this.picture_location = pics;
    }
  }

  ionViewDidEnter(): void {
    if (!this._current_value) {
      this.updateCounter(this._current_value);
    }
  }

  addValue(value: number) {
    if (this.counter === null || this.counter === undefined) {
      if (this.defaultValue === undefined) {
        this.counter = null;
      } else {
        this.counter = this.defaultValue;
      }
    }
    if (!this.counter) {
      this.counter = 0;
    }
    let new_value = this.counter + value;
    if (this.max && new_value > this.max) {
      new_value = this.max;
    }
    if (new_value < this.min) {
      new_value = this.min;
    }

    this.setValue(new_value);
  }

  setValue(new_value) {
    this.updateCounter(new_value);
    this._emitChangeEvent();
  }

  /** Implemented as part of ControlValueAccessor. */
  onChange = (value: any) => {};
  _emitChangeEvent() {
    this.onChange(this._current_value);
    this.valueChange.emit(this._current_value);
    this.pictureChange(false);
  }
  writeValue(value: any): void {
    if (typeof value === "string") {
      value = Number.parseInt(value, 10);
    }
    this.setValue(value);
    this.pictureChange(false);
  }
  registerOnChange(fn: (value: number | null) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  addPressedValue($event: any, value: number) {
    this.addValue(value);
  }

  updateValueFromCounter() {
    this.addValue(
      this.counter - this._current_value > 0 ? this._current_value : 0
    );
  }

  updateCounter(value: number | null) {
    if (
      !value &&
      this.defaultValue !== null &&
      this.defaultValue !== undefined
    ) {
      this._current_value = this.defaultValue;
    } else {
      this._current_value = value;
    }

    if (value !== this.dontKnow) {
      this.counter = this._current_value;
    } else {
      this.counter = null;
    }
  }
  unknowned() {
    this.setValue(this.dontKnow);
  }
  async setWheel() {
    const wheelValues: PickerColumnOption[] = [];
    for (let i = this.min; i < this.max; i++) {
      wheelValues.push({ text: i.toString(), value: i });
    }
    const selectedItemIndex = wheelValues.findIndex(
      (wi) => wi.value === this._current_value ?? 0
    );

    const options: PickerOptions = {
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Ok",
          handler: (result: PickerColumnOption) => {
            this.setValue(result[""].value);
          },
        },
      ],
      columns: [
        {
          name: this.label,
          options: wheelValues,
        },
      ],
    };
    const picker = await this.picker.create(options);
    picker.present();
  }

  holdCount(addedValue: number) {
    this.addValue(addedValue);
    this.endCount();
    this.timeoutHandler = setInterval(() => {
      this.addValue(addedValue);
    }, 100);
  }

  endCount() {
    if (this.timeoutHandler) {
      clearInterval(this.timeoutHandler);
      this.timeoutHandler = null;
    }
  }
}
