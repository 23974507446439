import { City } from "src/app/models/city";
import { MyForm, FormInfo } from "src/app/models/forms";
import { TeamNaming, UserTeamFormEntity } from "src/app/models/team";
import { filter, share } from "rxjs/operators";
import { db } from "./database";
import { Subject, Observable } from "rxjs";
import { FormStatus } from "../models/forms";

export type DBChangeType = "created" | "deleted" | "changed";
export interface DBChange {
  type: DBChangeType;
  story: string;
  key?: any;
}

export class BaseDatabaseService {
  databaseChange$ = new Subject<DBChange>();

  constructor() {
    this.openDB();
    const dbChange$ = this.databaseChange$;
    db.on("changes", function (changes) {
      changes.forEach(function (change) {
        switch (change.type) {
          case 1: // CREATED
            dbChange$.next({
              type: "created",
              key: change.key,
              story: change.table,
            });
            break;
          case 2: // UPDATED
            dbChange$.next({
              type: "changed",
              key: change.key,
              story: change.table,
            });
            break;
          case 3: // DELETED
            dbChange$.next({
              type: "deleted",
              key: change.key,
              story: change.table,
            });
            break;
        }
      });
    });
  }
  async openDB() {
    await db.open();
  }
  getTableChanges(table?: string): Observable<DBChange> {
    const tableChange = this.databaseChange$.asObservable();
    return tableChange.pipe(
      share(),
      filter((change) => !table || change.story === table)
    );
  }

  async findMyForms() {
    return await db.myForms.toArray();
  }
  async findAllCities() {
    return await db.cities.toArray();
  }

  async getCitiesById(id: any): Promise<City> {
    return await db.cities.where("id").equals(id).first();
  }

  async getTeamBySlug(slug: string): Promise<UserTeamFormEntity> {
    return await db.teams.where("slug").equals(slug).first();
  }

  async addTeam(
    team: UserTeamFormEntity,
    slug: string
  ): Promise<UserTeamFormEntity> {
    const utf: UserTeamFormEntity = { ...team, slug };
    return await db.teams.put(utf).then(() => utf);
  }
  async updateTeam(team: UserTeamFormEntity): Promise<UserTeamFormEntity> {
    return await db.teams.put(team, team.slug).then(() => team);
  }

  async getTeamNaming(team_slug: string): Promise<TeamNaming> {
    return await this.getTeamBySlug(team_slug).then((t) => {
      if (t) {
        return {
          teamName: t.name,
          teamUsername: t.name,
          entityName: t.own_entity.name,
          entityType: t.own_entity.type,
        };
      }
    });
  }

  async getFormById(id: any): Promise<MyForm> {
    return await db.myForms.where("id").equals(id).first();
  }

  /* async getFormCityById(id: any): Promise<MyForm> {
    return await db.myForms.where("city_id").equals(id).first();
  }*/

  async findCitiesByIds(ids: Array<number>) {
    return await db.cities.where("id").anyOf(ids);
  }

  async addForm(
    form: FormInfo,
    city: City,
    formStatus: FormStatus
  ): Promise<MyForm> {
    const mf: MyForm = {
      id: form.id,
      status: "new",
      city_id: city.id,
      created_at: new Date(),
      form,
      formStatus,
    };
    return await db.myForms.put(mf, mf.id).then(() => mf);
  }
  async updateForm(form: MyForm): Promise<MyForm> {
    return await db.myForms.put(form, form.id).then(() => form);
  }
  async updateCity(city: City): Promise<MyForm> {
    return await db.cities.put(city, city.id).then(() => city);
  }

  async addCity(city: City) {
    return db.cities.put(city, city.id);
  }

  async deleteForm(form_id: string) {
    return await db.myForms.where("id").equals(form_id).delete();
  }
}
