import { FieldInfo } from './../../models/fields-definitions';
import { Component, Input, EventEmitter, Output,forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const EVENT_ARRAY_FIELD: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EventsQuestionComponent),
  multi: true
};

@Component({
  selector: 'events-question',
  templateUrl: './events-question.component.html',
  styleUrls: ['./events-question.component.scss'],
  providers:[EVENT_ARRAY_FIELD]
})
export class EventsQuestionComponent implements ControlValueAccessor {
  @Output() valueChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  fieldValue:string[] = [];
  _fieldInfo: FieldInfo;
  customEventName = '';
  extraEvents: string[] = [];
  updateSelectedEvent:string = '';

  constructor() { }


  @Input() 
  set value( value: string[] | null | undefined){
    if(value && value instanceof Array){
      this.fieldValue =  value;
    }else{
      this.fieldValue = [];
    }
    this.updateExtraEvents();
  }
  @Input() 
  set fieldInfo(field: FieldInfo){
    this._fieldInfo = field;
    this.updateExtraEvents()
  }

    // events
    onChange = (value: any) => {};
    registerOnTouched(fn: any): void {}
    registerOnChange(fn: (value: any) => void): void {
      this.onChange = fn;
    }
    writeValue(value: any): void {
      this.value = value;
      this.triggerChange();
    }

  removeFromArray(set: any[]|undefined, value?: any){
    if(set){
      set.splice( set.indexOf(value), 1 );
    }
  }
 
  toggleEventValue(status: boolean | null, value:string){
    if(!this.fieldValue){
      this.fieldValue = [];
    }
    if(this.fieldValue?.includes(value)){
      this.removeFromArray(this.fieldValue ,value);
    }else{
      this.fieldValue.push(value);
    }
    this.triggerChange();
  }

  addCustomEventName( event?:KeyboardEvent ){
    if(!event || event.key ==="Enter"){
      if(this.customEventName &&  this.fieldValue && !this.fieldValue?.includes(this.customEventName)){
        this.fieldValue.push(this.customEventName);
        if(!this.extraEvents.includes(this.customEventName)){
          this.extraEvents.push(this.customEventName);
          this.extraEvents = this.extraEvents.slice();
        }
        this.triggerChange();
      }
      this.customEventName = '';
    }

  }

  updateExtraEvents(){
    this.extraEvents = this.fieldValue?.filter( e => !this._fieldInfo?.optionsDefinitions?.some((o)=> o.value===e))??[];
  }
  
  removeCustomEvent(v: string){
    if(this.fieldValue){
      this.fieldValue.splice(this.fieldValue?.indexOf(v), 1);
      this.triggerChange();
      this.updateExtraEvents();
    }
  }
  

  triggerChange(){
    this.valueChange.emit(this.fieldValue);
    this.onChange(this.fieldValue);

  }

}
