import { BaseNumberedPanelComponent } from './../base-numbered-panel/base-numbered-panel.component';
import { Component } from '@angular/core';

@Component({
    selector: 'numbered-panel',
    templateUrl: './numbered-panel.component.html',
    styleUrls: ['./numbered-panel.component.scss'],
})
export class NumberedPanelComponent extends BaseNumberedPanelComponent{
 
}
