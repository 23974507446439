import { ModalController } from "@ionic/angular";
import { Directive } from "@angular/core";
import { City } from "src/app/models/city";
import { UUID } from "../../models/main-types";

@Directive()
export abstract class BaseCityModalComponent {
  city: City;
  formId: UUID;

  constructor(protected modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }
}
