import { BaseQuestionComponent } from './../base-question/base-question.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AvailableOption } from './../../models/generic';
import { Component, Input, forwardRef } from '@angular/core';

export const RANGE_INPUT_FIELD: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RangequestionComponent),
  multi: true
};


@Component({
  selector: 'rangequestion',
  templateUrl: './rangequestion.component.html',
  styleUrls: ['./rangequestion.component.scss'],
  providers: [RANGE_INPUT_FIELD]
})
export class RangequestionComponent extends BaseQuestionComponent {
  @Input() options:AvailableOption[]=[]
}
