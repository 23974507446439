
	
  import Dexie from 'dexie';
  import 'dexie-observable';

  export const db = new Dexie ('Landinventur');


	// Declare tables, IDs and indexes
	db.version(1).stores({
		cities: 'id, name, zip, municipality, district, state, lat, lng ,zoom',
    myForms: 'city_id, id, status, created_at, submitted_at, form'
	});
  
  db.version(2).stores({});
  db.version(3).stores({
    cities: 'id, name, zip, municipality, district, state, lat, lng ,zoom',
    myForms: 'city_id, id, status, created_at, submitted_at, form',
    teams: 'slug, city, id, name, captain_name, email, telephone, deadline, lockedAt, type, entities, own_entity, team_city_id'
  });
  db.version(4).stores({
    myForms: null,
  });

  db.version(5).stores({
    myForms: 'id, city_id, status, created_at, submitted_at, form',
  });
  
