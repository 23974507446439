import { Component,  Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
export const UNIQUE_RADIOSET_QUESTION: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioSetComponent),
  multi: true
};

export interface AvailableOption {
  key: string;
  label: string;
  help?: string;
}


@Component({
  selector: 'radio-set',
  templateUrl: './radio-set.component.html',
  styleUrls: ['./radio-set.component.scss'],
  providers: [UNIQUE_RADIOSET_QUESTION],
})
export class RadioSetComponent implements ControlValueAccessor {
  @Output() readonly valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() size = 12;
  _current_value: string;
  _options_map: AvailableOption[] = [];
 

  constructor() {}

  @Input()
  set options(options: AvailableOption[]) {
      this._options_map = options;
  }

 


  /** Implemented as part of ControlValueAccessor. */
  onChange = (value: string) => {};

  private _emitChangeEvent() {
    this.onChange(this._current_value);
    this.valueChange.emit(this._current_value);
    
  }
  writeValue(value: any): void {
    this._current_value = value;
    this._emitChangeEvent();
  }
  registerOnChange(fn: (rating: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: any): void {}

  toggleValue(s: AvailableOption) {
    if(this._current_value !== s.key){
      this._current_value = s.key;
    }
    this._emitChangeEvent();
  }
}
