import {  Input, Directive, EventEmitter, Output } from '@angular/core';
import { QuestionStatus } from 'src/app/models/forms';

export interface QuestionsMap {
  page: number;
  status: QuestionStatus;
}

@Directive()
export abstract class BaseNumberedPanelComponent  {
  @Output() expansionChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() status: QuestionStatus;
  @Input() questionNumber: number;
  @Input() title = '';
  @Input() set questionDefinition(questions: QuestionsMap) {
    if(this.status !== questions.status){
      this.status = questions.status ?? '';
    }
    if(this.questionNumber !== questions.page){
      this.questionNumber = questions.page;
    }
  }
  @Input() expanded = false;
  constructor() {}

  toggleExpand() {
    this.expanded = !this.expanded;
    this.expansionChanged.emit(this.expanded);
  }
  getColor() {
    switch (this.status) {
      case 'completed':
        return 'success';
      case 'incomplete':
        return 'warning';
      case 'dontknow':
        return 'light';
      default:
        return '';
    }
  }
}
