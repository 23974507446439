import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { BaseCityModalComponent } from '../base-city-modal/base-city-modal.component';

@Component({
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
})
export class InfoPopupComponent extends BaseCityModalComponent{

  title: '';
  content: '';

  constructor(modalController: ModalController) {
    super(modalController);
   }

}
