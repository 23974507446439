import { Injectable } from '@angular/core';
import { BaseDatabaseService } from './databaseService';

export type DBChangeType = 'created' | 'deleted' | 'changed';
export interface DBChange {
  type: DBChangeType;
  story: string;
  key?: any
}

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends BaseDatabaseService{

}
