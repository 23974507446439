import { SwiperOptions } from 'swiper/types';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SwiperConfigurationService {
  constructor() { }
  
  getCardConfiguration(): SwiperOptions {
    return {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 5,
      navigation: true,
      pagination: { type: 'bullets' },
      scrollbar: false,
      rewind: true,
    }
  }
}
