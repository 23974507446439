import { Component,  Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
export const UNIQUE_CHECKBOXSET_QUESTION: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxSetComponent),
  multi: true
};

export interface AvailableOption {
  key: string;
  label: string;
  help?: string;
}


@Component({
  selector: 'checkbox-set',
  templateUrl: './checkbox-set.component.html',
  styleUrls: ['./checkbox-set.component.scss'],
  providers: [UNIQUE_CHECKBOXSET_QUESTION],
})
export class CheckboxSetComponent implements ControlValueAccessor {
  @Output() readonly valueChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() size = 6;
  _current_values: string[];
  _options_map: AvailableOption[] = [];
 

  constructor() {}

  @Input()
  set options(options: AvailableOption[]) {
      this._options_map = options;
  }

 


  /** Implemented as part of ControlValueAccessor. */
  onChange = (value: string[]) => {};

  private _emitChangeEvent() {
    this.onChange(this._current_values);
    this.valueChange.emit(this._current_values);
    
  }
  writeValue(value: []): void {
    this._current_values = value;
    this._emitChangeEvent();
  }
  registerOnChange(fn: (rating: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: any): void {}

  toggleValue(s: AvailableOption) {
    if(!this._current_values){
      this._current_values = [];
    }
    if (this._current_values.includes(s.key)) {
      this._current_values.splice( this._current_values.indexOf(s.key), 1 );
    } else {
      this._current_values.push(s.key) ;
    }
    this._emitChangeEvent();
  }
}
