import { InformationenComponent } from '../modals/informationen/informationen.component';
import { ModalController } from '@ionic/angular';
import { Directive, Input, HostListener } from '@angular/core';

type ExportTypes = 'information'|'';

@Directive({
  selector: '[helpModal]'
})
export class HelpModalDirective {

  @Input()
  helpModal: ExportTypes = 'information';
  constructor(private modal: ModalController) { }

  @HostListener('click') elementClick(){
    switch(this.helpModal){
      case'information':
      default:
        this.presentInfoModal();
    } 
  }

  async presentInfoModal() {
    const modal = await this.modal.create({ component: InformationenComponent});
    return await modal.present();
  }

}
