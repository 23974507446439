import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.modal.html',
  styleUrls: ['./no-internet.modal.scss'],
})
export class NoInternetModal  {

  constructor(
    private router: Router,
    private modalController:ModalController
  ){}

    dismiss(){
      this.modalController.dismiss()
    }

  goToHomepage() {
    this.dismiss();
    this.router.navigate(['/']);
  }
}
