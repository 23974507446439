import { HelpModalDirective } from './../directives/help-modal.directive';
import { InfoTextDirective } from './../directives/info-text.directive';
import { InfoPopupComponent } from './../modals/info-popup/info-popup.component';
import { NumberedPanelOutsideComponent } from './numbered-panel-outside/numbered-panel-outside.component';
import { RadioSetComponent } from './radio-set/radio-set.component';
import { EventsQuestionComponent } from './events-question/events-question.component';
import { CheckSelectableImageComponent } from './check-selectable-image/check-selectable-image.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangequestionComponent } from './rangequestion/rangequestion.component';
import { GeneralquestionComponent } from './generalquestion/generalquestion.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiquestionComponent } from './multiquestion/multiquestion.component';
import { SwiperModule } from 'swiper/angular';
import { NumberedPanelComponent } from './numbered-panel/numbered-panel.component';
import { CheckboxSetComponent } from './checkbox-set/checkbox-set.component';
import { InformationenComponent } from '../modals/informationen/informationen.component';



@NgModule({
  declarations: [
    GeneralquestionComponent,RangequestionComponent,MultiquestionComponent,
    NumberedPanelComponent,NumberedPanelOutsideComponent, CheckSelectableImageComponent, EventsQuestionComponent,
    RadioSetComponent, CheckboxSetComponent, InfoTextDirective, InfoPopupComponent,
    HelpModalDirective, InformationenComponent

  ],
  imports: [
    CommonModule,
    SwiperModule,
    FormsModule,
    IonicModule
  ],
  exports:[
    GeneralquestionComponent,RangequestionComponent,MultiquestionComponent,
    NumberedPanelComponent, NumberedPanelOutsideComponent, CheckSelectableImageComponent, EventsQuestionComponent,
    RadioSetComponent, CheckboxSetComponent, InfoTextDirective, InfoPopupComponent,
    HelpModalDirective, InformationenComponent
]
})
export class SharedComponentsModule { }
