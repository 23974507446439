import { PwaIosBarComponent } from './popover/pwa-ios-bar/pwa-ios-bar.component';
import { NgModule, Injectable } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedComponentsModule } from './components/shared-components.module';
import * as Hammer from 'hammerjs';
import { NoInternetModal } from './modals/no-internet/no-internet.modal';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [AppComponent, NoInternetModal, PwaIosBarComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(
      {
        mode:'md',
        scrollAssist: true,
        scrollPadding: true
      },
    ),
    AppRoutingModule,
    SharedComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:10000'
    }),
    HammerModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {  provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },

    ReactiveFormsModule, AndroidFullScreen ],
  bootstrap: [AppComponent],
})
export class AppModule {}
