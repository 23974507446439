import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component,  Input, EventEmitter, Output, forwardRef } from '@angular/core';

export const CHECK_SELECT_FIELD: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckSelectableImageComponent),
  multi: true
};

@Component({
  selector: 'check-selectable-image',
  templateUrl: './check-selectable-image.component.html',
  styleUrls: ['./check-selectable-image.component.scss'],
  providers: [CHECK_SELECT_FIELD]
})
export class CheckSelectableImageComponent implements ControlValueAccessor {
  @Output() valueChange: EventEmitter<boolean|null> = new EventEmitter<boolean|null>();

  status: boolean|null = null;
  @Input() title?: string;
  @Input() selectedValue:any = true;
  @Input() deselectedValue:any = false;
  //TODO handle array
  @Input() selectedImage?: string | string[];
  @Input() deselectedImage?: string| string[];

  constructor() { }

  @Input()
  set value(value: any) {
    if ( value === this.selectedValue) {
      this.status = true;
    }
    if ( value === this.deselectedValue) {
      this.status = false;
    }
  }
  get value(): any {
    if ( this.status === true) {
      return this.selectedValue;
    }
    if ( this.status === false) {
      return this.deselectedValue;
    }
    return null;
  }

  // events
  onChange = (value: any) => {};
  registerOnTouched(fn: any): void {}

  _emitChangeEvent() {
    const v = this.value;
    this.valueChange.emit(this.status);
    this.onChange(v);
  }
  writeValue(value: any): void {
    this.value = value;
    this._emitChangeEvent();
  }
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  toggleValue() {
    this.status = !this.status;
    this._emitChangeEvent();
  }
}
