import { FormularService } from "./services/formular.service";
import { CloseOverlaysService } from "./services/close-overlays.service";
import { UpdateService } from "./services/update.service";
import { Component, HostListener, OnInit } from "@angular/core";
import { AndroidFullScreen } from "@awesome-cordova-plugins/android-full-screen/ngx";
import { StatusBar } from "@capacitor/status-bar";
import { Platform } from "@ionic/angular";
import { Navigation, NavigationStart, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  onlineStatus = false;
  showIosBar = false;
  constructor(
    private platform: Platform,
    private router: Router,
    private androidFullScreen: AndroidFullScreen,
    private formularService: FormularService,
    private overlayService: CloseOverlaysService,
    updatePWA: UpdateService
  ) {
    this.onlineStatus = window.navigator.onLine;
    this.androidFullScreen
      .isImmersiveModeSupported()
      .then((e) => {
        //  this.androidFullScreen.immersiveMode();
        console.log(e, "Immersive mode supported");
      })
      .catch((err) => console.log(err));
    StatusBar.setOverlaysWebView({
      overlay: true,
    }).catch((e) => console.log(e));

    if (!this.platform.is("cordova") && !this.platform.is("capacitor")) {
      setInterval(() => updatePWA.checkUpdates(), 5 * 60 * 1000);
    }

    this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === "popstate") {
          overlayService.trigger();
        }
      }
    });
    setInterval(() => this.requestSync(), 5 * 60 * 1000);
    this.requestSync();

    this.platform.backButton.subscribe(() => overlayService.trigger());
  }

  ngOnInit(): void {
    this.showIosBar = this.isIos() && !this.isInStandaloneMode();
    console.log(this.showIosBar);
  }

  @HostListener("window:offline")
  networkOffline(): void {
    this.onlineStatus = false;
  }
  @HostListener("window:online")
  networkOnline(): void {
    console.log("online");
    this.onlineStatus = true;
    this.requestSync();
  }

  requestSync() {
    if (this.onlineStatus) {
      this.formularService.syncMyForms();
    }
  }

  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  // Detects if device is in standalone mode
  isInStandaloneMode = () =>
    "standalone" in window.navigator ||
    window.matchMedia("(display-mode: standalone)").matches;
}
