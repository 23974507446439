import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home-locations",
    pathMatch: "full",
  },

  {
    path: "download-city-maps",
    loadChildren: () =>
      import("./pages/download-city-maps/download-city-maps.module").then(
        (m) => m.DownloadCityMapsPageModule
      ),
  },
  {
    path: "form-type",
    loadChildren: () =>
      import("./pages/form-type/form-type.module").then(
        (m) => m.FormTypePageModule
      ),
  },

  {
    path: "ubersicht",
    loadChildren: () =>
      import("./pages/ubersicht/ubersicht.module").then(
        (m) => m.UbersichtPageModule
      ),
  },
  {
    path: "tabs",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "kartierung",
    loadChildren: () =>
      import("./pages/mapping/mapping.module").then((m) => m.MappingPageModule),
  },

  {
    path: "absenden",
    loadChildren: () =>
      import("./pages/absenden/absenden.module").then(
        (m) => m.AbsendenPageModule
      ),
  },
  {
    path: "newsletter",
    loadChildren: () =>
      import("./pages/newsletter/newsletter.module").then(
        (m) => m.NewsletterPageModule
      ),
  },
  {
    path: "home-locations",
    loadChildren: () =>
      import("./pages/home-locations/home-locations.module").then(
        (m) => m.HomeLocationsPageModule
      ),
  },
  {
    path: "missing-city",
    loadChildren: () =>
      import("./modals/missing-city/missing-city.module").then(
        (m) => m.MissingCityPageModule
      ),
  },
  {
    path: "team/form",
    loadChildren: () =>
      import("./pages/team/team.module").then((m) => m.TeamPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: "always",
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
