import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef } from '@angular/core';
import { BaseQuestionComponent } from '../base-question/base-question.component';

export const MULTI_QUESTION_INPUT_FIELD: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiquestionComponent),
  multi: true
};

@Component({
  selector: 'multiquestion',
  templateUrl: './multiquestion.component.html',
  styleUrls: ['./multiquestion.component.scss'],
  providers: [MULTI_QUESTION_INPUT_FIELD]
})
export class MultiquestionComponent extends BaseQuestionComponent {
  }

